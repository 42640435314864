<template>
  <v-container v-if="!jobIsEmpty">
    <JobHeader />
    <RoutingTemplates
      :onlyActive="true"
      v-on:chosenRouting="chooseThisRoutingTemplate"
    />
    <v-row>
      <v-col>
        <v-card @click="chooseThisRoutingTemplate(null)">
          <v-card-title class="grey lighten-2">Nieuwe Route</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import services from "../services/services.js";
import JobHeader from "@/components/JobHeader";
import RoutingTemplates from "@/components/RoutingTemplates";
export default {
  components: { JobHeader, RoutingTemplates },
  beforeCreate() {
    if (this.$store.getters.jobIsEmpty) {
      this.$router.push({ name: "start" });
    }
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    jobIsEmpty() {
      return this.$store.getters.jobIsEmpty;
    },
  },
  methods: {
    chooseThisRoutingTemplate(routing) {
      if (routing) {
        this.$store.commit("COPY_ROUTING", {
          routing,
        });
      } else {
        this.$store.commit("EMPTY_ROUTING");
      }
      this.$router.push({ name: "board" });
    },
  },
};
</script>
